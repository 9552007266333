// data form
export const columns = [
    { name: 'nom', label: 'Nom & Prénom', rule: 'isRequired', type: 'text' },
    { name: 'annee', label: 'Année de naissance', rule: 'isRequired', type: 'text' },
    { name: 'gsm', label: 'GSM', rule: 'isRequired', type: 'text' },
    { name: 'email', label: 'Email', rule: 'isRequired', type: 'email' },
    { name: 'ville', label: 'Ville de résidance', rule: 'isRequired', type: 'text' },
    { name: 'cin', label: 'CIN/Passeport', rule: 'isRequired', type: 'text' },
    {
        name: 'gender',
        label: 'Genre',
        type: 'select',
        rule: 'isRequired', 
        options: ['Homme', 'Femme'],
    },
    /* {
        name: 'pack',
        label: 'Votre choix de pack',
        type: 'select',
        rule: 'isRequired',
        options: ['Pack complet : 1500 DH', 'Pack Dossard : 800 DH']
    }, */
    {
        name: 'participation',
        label: 'Type de participation',
        type: 'select',
        rule: 'isRequired', 
        options: ['Par équipe', 'Individuelle']
    },

    {
        name: 'tshirt',
        label: 'Taille T-shirt',
        type: 'select',
        rule: 'isRequired', 
        options: ['S', 'M', 'XL', 'XXL'],
    },
    { name: 'nationalite', label: 'Nationalité', type: 'text' },
    { name: 'transfert', label: 'Transfert Ag->Taf.', type: 'checkbox' },
    { name: 'louer', label: 'Louer VTT sur place', type: 'checkbox' },
    { name: 'reglement', label: 'J\'ai lu et accepter; le réglement', rule: 'isRequired', type: 'checkbox' },
]



export const formInputs =
    // inputs type text
    columns
        .map((col) => {
            const { name, rule, label, type, options } = col
            return {
                label: label,
                type: type ? type : 'text',
                name: name,
                placeholder: label,
                options: options,
                // check if there is a validation rule for the
                // input and set the validatione rule
                ...(rule && { validationRule: rule }),
            }
        })


///////////////////////////////////////
export const getInitialState = () => {
    let initialState = {}
    // remove radio button name from initialState
    const initialInputs = formInputs
        .filter((el) => el.name !== 'product')
        .map((col) => col.name)
    for (const value of initialInputs) {
        initialState = { ...initialState, [value]: '' }
    }
    return initialState
}


