import React, { useEffect, useState } from 'react';

import Layout from '../layouts/layout';
import Login from '../layouts/login';
import List from '../components/table';

import { btnPrimary, section } from '../styles/styles';
import { navigate } from 'gatsby';

import { auth } from '../firebase'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import useAuth from '../hooks/useAuth';


const Admin = ({ location }) => {

    const { pending, isSignedIn, user, auth } = useAuth()

    const entries = performance.getEntriesByType("navigation")[0];
//|| entries.type === 'reload'
    if (pending) {
        return <h1>waiting...</h1>
    }

    if (!isSignedIn || location?.state?.prevPath !== '/signin') {
        navigate("/signin")
        return null
    }




    return (
        <Layout>
            <section className={`${section} relative`}>
                <div className="wrapper-lg sm:px-6 lg:px-8">
                    <div className="px-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
                        <div className="max-w-2xl mx-auto text-center">
                            <h1 className="title sm:text-5xl">
                                List des participants
                            </h1>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <List />
                    </div>

                </div>
            </section>
        </Layout>
    )

}

export default Admin