import React, { useEffect, useState } from 'react'
import { db } from "../firebase";
import { getDocs, collection, query, orderBy, doc, deleteDoc } from 'firebase/firestore'

import { Table, Header, HeaderCell, HeaderRow, Body, Row, Cell } from '@table-library/react-table-library';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { input, btnOutline, btnPrimary } from '../styles/styles';

import { columns } from '../content/inscription'
import Modal from './modal';

const key = 'Nom & Prénom';

const List = () => {
    let [users, setUsers] = useState([])
    const [search, setSearch] = React.useState('');
    const [showModal, setShowModal] = useState(false)
    const [popup, setPopup] = useState({
        show: false, // initial values set to false and null
        id: null,
    });

    let data = { nodes: users };
    data = {
        nodes: users.filter((item) => item.nom.toLowerCase().includes(search.toLowerCase())),
    };

    const getUsers = async () => {
        const usersCollectionRef = collection(db, "camptafraout-23");
        try {
            const response = await getDocs(query(usersCollectionRef, orderBy("createdAt", "desc")));
            const users = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
           // saveToStorage(users)
            setUsers(users)
        } catch (error) {
            console.log(error.message);
        }
    };

    const COLUMNS = [
        { label: 'Task', renderCell: (item) => item.nom },
        { label: 'Task', renderCell: (item) => item.annee },
        { label: 'Task', renderCell: (item) => item.gsm },
        { label: 'Task', renderCell: (item) => item.ville },
        { label: 'Task', renderCell: (item) => item.cin },
        { label: 'Task', renderCell: (item) => item.gender },
        { label: 'Task', renderCell: (item) => item.participation ? item.participation : item.participtaion },
        { label: 'Task', renderCell: (item) => item.tshirt },
        { label: 'Task', renderCell: (item) => item.nationalite },
        { label: 'Task', renderCell: (item) => item.transfert ? 'Oui' : 'Non' },
        { label: 'Task', renderCell: (item) => item.louer ? 'Oui' : 'Non' },

    ];



    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const theme = useTheme({
        //Table: ` border: 1px solid #e5e7eb;background-color: white; border-radius: 10px; `,
        Table: `
        grid-template-columns: minmax(0px,185px) minmax(0px, 1fr) minmax(0px, 150px) minmax(0px, 250px) minmax(0px, 120px) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr);`,
        HeaderRow: `
      background-color: white;
      border-bottom: 1px solid gray;
    `,
        HeaderCell: `padding: 10px 8px; font-size: 0.9rem;`
        ,
        Row: `
      &:nth-of-type(odd) {
        background-color:#f1f5f9;
        border-bottom: 1px solid #c5c5c5;
        border-width: 2;
    }
    
    &:nth-of-type(even) {
          background-color: white;
        }
        `,
        Cell: `padding: 8px; font-size: .9rem;`
    });


    useEffect(() => {
        getUsers()
    }, [])

    const escapeCsvCell = (cell) => {
        if (cell == null) {
            return '';
        }
        const sc = cell.toString().trim();
        if (sc === '' || sc === '""') {
            return sc;
        }
        if (sc.includes('"') || sc.includes(',') || sc.includes('\n') || sc.includes('\r')) {
            return '"' + sc.replace(/"/g, '""') + '"';
        }
        return sc;
    };

    const makeCsvData = (columns, data) => {
        return data.reduce((csvString, rowItem) => {
            return (
                csvString +
                columns.map(({ accessor }) => escapeCsvCell(accessor(rowItem))).join(',') +
                '\r\n'
            );
        }, columns.map(({ name }) => escapeCsvCell(name)).join(',') + '\r\n');
    };

    const downloadAsCsv = (columns, data, filename) => {
        const csvData = makeCsvData(columns, data);
        const csvFile = new Blob([csvData], { type: 'text/csv' });
        const downloadLink = document.createElement('a');

        downloadLink.display = 'none';
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleDownloadCsv = () => {
        const columns = [
            { accessor: (item) => item.nom, name: 'Nom & Prénom' },
            { accessor: (item) => item.annee, name: 'Annee de naissance' },
            { accessor: (item) => item.gsm, name: 'GSM' },
            { accessor: (item) => item.email, name: 'Email' },
            { accessor: (item) => item.ville, name: 'Ville' },
            { accessor: (item) => item.cin, name: 'CIN / Passe.' },
            { accessor: (item) => item.gender, name: 'Genre' },
            { accessor: (item) => item.participation, name: 'Type de Parti.' },
            { accessor: (item) => item.tshirt, name: 'Taille T-shirt' },
            { accessor: (item) => item.nationalite, name: 'Nationalite' },
            { accessor: (item) => item.transfert, name: 'Transfert AG-TAF.' },
            { accessor: (item) => item.louer, name: 'Location VTT' },
        ];

        downloadAsCsv(columns, data.nodes, 'Liste-des-participants');
    };

    const renderHeader = (value) => {
        switch (value) {
            case 'nom':
                return 'Nom & Prénom';
                break;
            case 'annee':
                return 'Année Nais.';
                break;
            case 'ville':
                return 'Ville';
                break;
            case 'cin':
                return 'CIN/Pass.';
                break;
            case 'transfert':
                return 'Transfert AG-TAF.';
                break;
            case 'louer':
                return 'Louer VTT';
                break;
            default:
                return value;
        }
    }






    // This will show the Cofirmation Box
    const handleDelete = (id) => {
        /* document.body.style.overflow = "hidden";
        document.body.style.height = '100vh' */
        setPopup({
            show: true,
            id,
        });
    };

    // This will perform the deletion and hide the Confirmation Box

    const handleDeleteTrue = () => {
        if (popup.show && popup.id) {
            const docRef = doc(db, "camptafraout-23", popup.id);
            deleteDoc(docRef)
                .then(() => getUsers())
                .catch(err => console.log(err.message))
            setPopup({
                show: false,
                id: null,
            });
        }
    };

    // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

    const handleDeleteFalse = () => {
        setPopup({
            show: false,
            id: null,
        });
    };




/* 
    const saveToStorage = (data) => {
        localStorage.setItem("camptafraout-23", JSON.stringify(data))
    }
 */
    return (
        <>
            {popup.show && <Modal
                handleDeleteFalse={handleDeleteFalse}
                handleDeleteTrue={handleDeleteTrue} />}
            <div className="mb-2 border flex px-4 py-4 rounded-lg bg-white justify-between items-center">
                <label htmlFor="search" className='flex items-center'>
                    <span className='mr-3'>
                        Recherche
                    </span>
                    <input id="search" placeholder='Nom & Prénom' type="text" value={search} onChange={handleSearch} className={`${input} py-2`} />
                </label>
                <div>
                    <p className='text-secondary mt-0 font-medium'>
                        {users.length} participants
                    </p>
                </div>
                <button type="button" className={btnOutline} onClick={handleDownloadCsv}>
                    Télécharger CSV
                </button>
            </div>



            <Table data={data} theme={theme}>
                {(tableList) => (
                    <>
                        <Header>
                            <HeaderRow>
                                <HeaderCell>Nom & Pre.</HeaderCell>
                                <HeaderCell>Année Nais.</HeaderCell>
                                <HeaderCell>GSM</HeaderCell>
                                <HeaderCell>Email</HeaderCell>
                                <HeaderCell>Pack</HeaderCell>
                                <HeaderCell>Ville</HeaderCell>
                                <HeaderCell>Cin/Pass.</HeaderCell>
                                <HeaderCell>Genre</HeaderCell>
                                <HeaderCell>type Parti.</HeaderCell>
                                <HeaderCell>Taille</HeaderCell>
                                {/*   <HeaderCell>Nationalité</HeaderCell> */}
                                <HeaderCell>Transfert</HeaderCell>
                                <HeaderCell>Location</HeaderCell>
                                <HeaderCell>action</HeaderCell>
                            </HeaderRow>
                        </Header>

                        <Body>
                            {tableList.map((item) => (
                                <Row key={item.id} item={item} className='py-14 border'>
                                    <Cell >{item.nom}</Cell>
                                    <Cell>{item.annee}</Cell>
                                    <Cell>{item.gsm}</Cell>
                                    <Cell>{item.email}</Cell>
                                    <Cell>{item.pack}</Cell>
                                    <Cell>{item.ville}</Cell>
                                    <Cell>{item.cin}</Cell>
                                    <Cell>{item.gender}</Cell>
                                    <Cell>{item.participation}</Cell>
                                    <Cell>{item.tshirt}</Cell>
                                    {/*   <Cell>{item.nationalite}</Cell> */}
                                    <Cell>{item.transfert ? 'Oui' : 'Non'}</Cell>
                                    <Cell>{item.louer ? 'Oui' : 'Non'}</Cell>
                                    <Cell>
                                        <button
                                            className='bg-primary py-1 px-2 text-white rounded-lg hover:opacity-75'
                                            onClick={() => handleDelete(item.id)}
                                        >
                                            Sup.
                                        </button>
                                    </Cell>
                                </Row>
                            ))}
                        </Body>
                    </>
                )}
            </Table>

            {/* <div class="flex flex-col bg-white rounded-lg" >
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="overflow-hidden">
                            <table class="min-w-full">
                                <thead class="border-b">
                                    <tr>
                                        {columns.slice(0, -1).map(col => {
                                            return (

                                                <th scope="col" class="text-sm font-semibold text-gray-900 px-6 py-4 text-left" key={col.name}>
                                                    {renderHeader(col.name)}
                                                </th>
                                            )
                                        })}

                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(item => {
                                        return (
                                            <tr class="border-b odd:bg-slate-100">
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-900">{item.nom}</td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-900">{item.annee}</td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-900">{item.gsm}</td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-900">{item.email}</td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-900">{item.ville}</td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-900">{item.cin}</td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-900">{item.genre}</td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-900">{item.participation ? item.participation : item.participtaion}</td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-900">{item.tshirt}</td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-900">{item.nationalite}</td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-900">{item.transfert ? 'Oui' : 'Non'}</td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-900">{item.louer ? 'Oui' : 'Non'}</td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div > */}
        </>

    );
};


export default List







