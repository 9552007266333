import React from 'react'
import { btnOutline, btnPrimary } from '../styles/styles'


const styles = 'w-screen h-full absolute top-0 left-0 z-50  flex justify-center items-center'
const overlay = 'w-full h-full bg-slate-900 absolute top-0 left-0 opacity-90 '
const modal = 'w-[400px] h-[200px] py-8 px-8 bg-white rounded-lg opacity-100 z-50'

const Modal = ({ handleDeleteFalse, handleDeleteTrue }) => {


    return (
        <section className={styles}>
            <div className={overlay}>
            </div>
            <div className={modal}>
                Etes vous sûr de vouloir supprimer ce particiapant ?
                <div className='space-x-2 mt-6 flex flex-row'>
                    <button class="px-8 border border-primary py-3"
                        style={{ width: '130px' }}
                        onClick={handleDeleteTrue}>
                        Oui
                    </button>
                    <button class="px-8 border border-primary bg-primary py-3"
                        style={{ width: '130px' }}
                        onClick={handleDeleteFalse}>Non</button>
                </div>
            </div>
        </section>
    )
}

export default Modal